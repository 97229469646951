export type ApplicationSettings = {
  ELOCAL_API_URL: string;
  ELOCAL_API_KEY: string;
  ELOCAL_GEO_API_URL: string;
  CALLAPRO_WEBSITE_URL: string;
  ENABLE_EMBEDDED_MODE: string;
  NEXT_PUBLIC_AMPLIFY_REBUILD_ID: string;
  NEXT_PUBLIC_AMPLIFY_REBUILD_TOKEN: string;
  NEXT_PUBLIC_AWS_ACCESS_KEY_ID: string;
  NEXT_PUBLIC_AWS_API_GW_TOKEN: string;
  NEXT_PUBLIC_AWS_ELOCAL_KEY: string;
  NEXT_PUBLIC_AWS_REGION: string;
  NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY: string;
  NEXT_PUBLIC_AWS_SLACK_ALERTS_CHANNEL_ID: string;
  NEXT_PUBLIC_AWS_SLACK_TOKEN: string;
  NEXT_PUBLIC_CAP_AUTH_TOKEN: string;
  ENVIRONMENT: string;
  NEXT_PUBLIC_SENDER_EMAIL_ADDRESS: string;
  NODE_ENV: string;
};

export const applicationSettings: ApplicationSettings = {
  ELOCAL_API_URL: process.env.ELOCAL_API_URL as string,
  ELOCAL_API_KEY: process.env.ELOCAL_API_KEY as string,
  ELOCAL_GEO_API_URL: 'https://api-geo.elocal.com',
  CALLAPRO_WEBSITE_URL: process.env.NEXT_PUBLIC_CALLAPRO_WEBSITE_URL ?? '',
  ENABLE_EMBEDDED_MODE: process.env.ENABLE_EMBEDDED_MODE as string,
  NEXT_PUBLIC_AMPLIFY_REBUILD_ID: process.env.NEXT_PUBLIC_AMPLIFY_REBUILD_ID as string,
  NEXT_PUBLIC_AMPLIFY_REBUILD_TOKEN: process.env.NEXT_PUBLIC_AMPLIFY_REBUILD_TOKEN as string,
  NEXT_PUBLIC_AWS_ACCESS_KEY_ID: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID as string,
  NEXT_PUBLIC_AWS_API_GW_TOKEN: process.env.NEXT_PUBLIC_AWS_API_GW_TOKEN as string,
  NEXT_PUBLIC_AWS_ELOCAL_KEY: process.env.NEXT_PUBLIC_AWS_ELOCAL_KEY as string,
  NEXT_PUBLIC_AWS_REGION: process.env.NEXT_PUBLIC_AWS_REGION as string,
  NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY as string,
  NEXT_PUBLIC_AWS_SLACK_ALERTS_CHANNEL_ID: process.env.NEXT_PUBLIC_AWS_SLACK_ALERTS_CHANNEL_ID as string,
  NEXT_PUBLIC_AWS_SLACK_TOKEN: process.env.NEXT_PUBLIC_AWS_SLACK_TOKEN as string,
  NEXT_PUBLIC_CAP_AUTH_TOKEN: process.env.NEXT_PUBLIC_CAP_AUTH_TOKEN as string,
  ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT as string,
  NEXT_PUBLIC_SENDER_EMAIL_ADDRESS: process.env.NEXT_PUBLIC_SENDER_EMAIL_ADDRESS as string,
  NODE_ENV: process.env.NODE_ENV as string
};
