export interface CategorySubset {
  id: number;
  category_search_id?: number;
  name: string;
  name_singular: string;
  name_adjective: string;
  name_plural: string;
  name_possessive: string;
  parent_id: number;
  phone_number: string | number;
  sms_number?: string | number;
  showPhoneNumber?: boolean;
  url_slug: string;
  headline: string;
  modal_title: string;
  modal_title_success: string;
  sms_headline: string;
  subheadline: string;
  cta_copy?: string;
  hideTags: boolean;
  cta_label?: string;
  use_caseys_theme?: boolean;
  use_caseys_theme_blue_button?: boolean;
  use_dark_theme?: boolean;
  use_dark_header?: boolean;
  category_dude_image?: string;
  category_dude_image_fallback?: string;
  logo_color?: string;
  hide_popular_categories?: boolean;
  use_light_theme_landing_layout?: boolean;
  use_custom_question_flow?: boolean;
  exclude_from_popular_categories?: boolean;
  show_phone_in_header?: boolean;
  gtag_phone_config_id?: string;
  show_lead_form_after_profindr_flow?: boolean;
  price_guide_label?: string;
  price_guide_data?: string;
  articles_category_name?: string;
  articles_category_name_singular?: string;
  articles_url_slug?: string;
  meta: {
    author: string;
    category_name: string;
    og_description: string;
    og_title: string;
    og_url: string;
    description: string;
    keywords: string;
    title: string;
    canonical: string;
  };
  meta_srp_zip?: {
    page_name: string;
    og_title: string;
    description: string;
    title: string;
  };
  meta_srp_city?: {
    page_name: string;
    og_title: string;
    description: string;
    title: string;
  };
}

/**
 * CATEGORY_SUBSETS is loosely ordered based on popularity. Changing the order of entries or adding new entries could change content elsewhere
 * * Example: `CategoryLinks` is dependant on the order
 */
export const CATEGORY_SUBSETS: CategorySubset[] = [
  {
    id: 10000,
    name: 'Plumbers',
    name_singular: 'Plumber',
    name_adjective: 'Plumbing',
    name_plural: 'Plumbers',
    name_possessive: "Plumber's",
    parent_id: 100,
    phone_number: 8667206270,
    sms_number: 8886047504,
    showPhoneNumber: true,
    url_slug: 'plumbers',
    headline: 'Plumbing Pros',
    modal_title: 'Find a Plumber Fast.',
    modal_title_success: "We've found a Plumber!",
    sms_headline: "CallAPro - We've got a plumber waiting for your call.",
    subheadline: 'Get help fast. We service {{City|your area}} and surrounding cities. Same-day service. Call <a href="tel:8667206270">(866) 720-6270</a> to schedule your plumber.',
    hideTags: true,
    hide_popular_categories: true,
    show_phone_in_header: true,
    gtag_phone_config_id: 'AW-11164146131/xf1ACMaphdAYENOzvcsp',
    show_lead_form_after_profindr_flow: true,
    price_guide_data: 'plumbers',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Plumbers',
      og_description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      og_title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      og_url: 'https://www.callapro.com/plumbers',
      description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      keywords:
        'Plumbers Directory, Find A Local Plumber, The 1 Plumbing Site, plumbers, plumber, find a plumber, local plumbers, local plumber, plumbing, plumbing companies, plumbing contractors, residential plumbers, commercial plumbers, licensed contractors, sewer drain cleaning contractors, emergency plumbers, Plumbing Problems and Repairs',
      title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      canonical: 'https://www.callapro.com/plumbers/'
    }
  },
  {
    id: 10000,
    name: 'Plumbers',
    name_singular: 'Plumber',
    name_adjective: 'Plumbing',
    name_plural: 'Plumbers',
    name_possessive: "Plumber's",
    parent_id: 100,
    phone_number: 8668342260,
    sms_number: 8886047504,
    showPhoneNumber: true,
    url_slug: 'pl',
    headline: 'Get Plumbing Help Fast!',
    modal_title: 'Find a Plumber Fast.',
    modal_title_success: "We've found a Plumber!",
    sms_headline: "CallAPro - We've got a plumber waiting for your call.",
    subheadline: 'Get connected to a local plumbing professional right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_phone_in_header: true,
    show_lead_form_after_profindr_flow: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Plumbers',
      og_description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      og_title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      og_url: 'https://www.callapro.com/pl',
      description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      keywords:
        'Plumbers Directory, Find A Local Plumber, The 1 Plumbing Site, plumbers, plumber, find a plumber, local plumbers, local plumber, plumbing, plumbing companies, plumbing contractors, residential plumbers, commercial plumbers, licensed contractors, sewer drain cleaning contractors, emergency plumbers, Plumbing Problems and Repairs',
      title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      canonical: 'https://www.callapro.com/pl/'
    }
  },
  {
    id: 11000,
    name: 'Roofers',
    name_singular: 'Roofer',
    name_adjective: 'Roofing',
    name_plural: 'Roofers',
    name_possessive: "Roofer's",
    parent_id: 100,
    phone_number: 8884925809,
    sms_number: 8885998503,
    showPhoneNumber: true,
    url_slug: 'roofers',
    headline: 'Get Help With Your Roof!',
    modal_title: 'Find a Roofer Fast.',
    modal_title_success: "We've found a Roofer!",
    sms_headline: "CallAPro - We've got a roofer waiting for your call.",
    subheadline: 'Get connected to a local roofer right when you need them.',
    hideTags: true,
    use_custom_question_flow: true,
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Roofers',
      og_description: "Need a Roofer?  Search the Nation's #1 Directory of Local Roofers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Roofer Today!",
      og_title: 'Find Local Roofers | #1 Roofer Directory | Get Roofing Quotes',
      og_url: 'https://www.callapro.com/roofers',
      description: "Need a Roofer?  Search the Nation's #1 Directory of Local Roofers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Roofer Today!",
      keywords:
        'Roofers, Roofer, find a Roofer, local Roofers, local Roofer, Roofing, Roofing companies, Roofing contractors, residential Roofers, commercial Roofers, licensed contractors, emergency Roofers, Roof Leaks, Roofing Problems and Repairs',
      title: 'Find Local Roofers | #1 Roofer Directory | Get Roofing Quotes',
      canonical: 'https://www.callapro.com/roofers/'
    }
  },
  {
    id: 5000,
    name: 'Electricians',
    name_singular: 'Electrician',
    name_adjective: 'Electrical',
    name_plural: 'Electricians',
    name_possessive: "Electrician's",
    parent_id: 100,
    phone_number: 8776074726,
    sms_number: 8559561418,
    showPhoneNumber: true,
    url_slug: 'electricians',
    headline: 'Find an Electrician Today!',
    modal_title: 'Find an Electrician Fast.',
    modal_title_success: "We've found an Electrician!",
    sms_headline: "CallAPro - We've got an electrician waiting for your call.",
    subheadline: 'Get connected to a local electrician right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    show_phone_in_header: true,
    gtag_phone_config_id: 'AW-11164146131/2tRVCP7YvtMYENOzvcsp',
    show_lead_form_after_profindr_flow: true,
    price_guide_data: 'electricians',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Electricians',
      og_description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      og_title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      og_url: 'https://www.callapro.com/electricians',
      description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      keywords:
        'Electricians, Electrician, find an Electrician, local Electricians, local Electrician, Electric, Electric companies, Electric contractors, residential Electricians, commercial Electricians, licensed contractors, emergency Electricians, Electrical Contractors, Electric Repairs, Electrical Problems',
      title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      canonical: 'https://www.callapro.com/electricians//'
    }
  },
  {
    id: 5000,
    name: 'Electricians',
    name_singular: 'Electrician',
    name_adjective: 'Electrical',
    name_plural: 'Electricians',
    name_possessive: "Electrician's",
    parent_id: 100,
    phone_number: 8776074726,
    sms_number: 8559561418,
    showPhoneNumber: true,
    url_slug: 'electrician',
    headline: 'Electrical Service',
    modal_title: 'Find an Electrician Fast.',
    modal_title_success: "We've found an Electrician!",
    sms_headline: "CallAPro - We've got an electrician waiting for your call.",
    subheadline: 'We service {{City|your area}} and surrounding cities. Same-day service available. Call <a href="tel:8776074726">(877) 607-4726</a> to schedule a service.',
    hideTags: true,
    hide_popular_categories: true,
    use_light_theme_landing_layout: true,
    exclude_from_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Electricians',
      og_description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      og_title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      og_url: 'https://www.callapro.com/electrician',
      description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      keywords:
        'Electricians, Electrician, find an Electrician, local Electricians, local Electrician, Electric, Electric companies, Electric contractors, residential Electricians, commercial Electricians, licensed contractors, emergency Electricians, Electrical Contractors, Electric Repairs, Electrical Problems',
      title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      canonical: 'https://www.callapro.com/electrician/'
    }
  },
  {
    id: 5000,
    name: 'Electricians',
    name_singular: 'Electrician',
    name_adjective: 'Electrical',
    name_plural: 'Electricians',
    name_possessive: "Electrician's",
    parent_id: 100,
    phone_number: 8776074726,
    sms_number: 8559561418,
    showPhoneNumber: true,
    url_slug: 'el',
    headline: 'Find an Electrician Today!',
    modal_title: 'Find an Electrician Fast.',
    modal_title_success: "We've found an Electrician!",
    sms_headline: "CallAPro - We've got an electrician waiting for your call.",
    subheadline: 'Get connected to a local electrician right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Electricians',
      og_description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      og_title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      og_url: 'https://www.callapro.com/el',
      description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      keywords:
        'Electricians, Electrician, find an Electrician, local Electricians, local Electrician, Electric, Electric companies, Electric contractors, residential Electricians, commercial Electricians, licensed contractors, emergency Electricians, Electrical Contractors, Electric Repairs, Electrical Problems',
      title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      canonical: 'https://www.callapro.com/el//'
    }
  },
  {
    id: 5000,
    name: 'Electricians',
    name_singular: 'Electrician',
    name_adjective: 'Electrical',
    name_plural: 'Electricians',
    name_possessive: "Electrician's",
    parent_id: 100,
    phone_number: 8668281276,
    sms_number: 8559561418,
    showPhoneNumber: true,
    url_slug: 'elr',
    headline: 'Find an Electrician Today!',
    modal_title: 'Find an Electrician Fast.',
    modal_title_success: "We've found an Electrician!",
    sms_headline: "CallAPro - We've got an electrician waiting for your call.",
    subheadline: 'Get connected to a local electrician right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Electricians',
      og_description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      og_title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      og_url: 'https://www.callapro.com/elr',
      description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      keywords:
        'Electricians, Electrician, find an Electrician, local Electricians, local Electrician, Electric, Electric companies, Electric contractors, residential Electricians, commercial Electricians, licensed contractors, emergency Electricians, Electrical Contractors, Electric Repairs, Electrical Problems',
      title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      canonical: 'https://www.callapro.com/elr/'
    }
  },
  {
    id: 5000,
    name: 'Electricians',
    name_singular: 'Electrician',
    name_adjective: 'Electrical',
    name_plural: 'Electricians',
    name_possessive: "Electrician's",
    parent_id: 100,
    phone_number: 8668281276,
    sms_number: 8559561418,
    showPhoneNumber: true,
    url_slug: 'elb',
    headline: 'Find an Electrician Today!',
    modal_title: 'Find an Electrician Fast.',
    modal_title_success: "We've found an Electrician!",
    sms_headline: "CallAPro - We've got an electrician waiting for your call.",
    subheadline: 'Get connected to a local electrician right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Electricians',
      og_description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      og_title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      og_url: 'https://www.callapro.com/elb',
      description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      keywords:
        'Electricians, Electrician, find an Electrician, local Electricians, local Electrician, Electric, Electric companies, Electric contractors, residential Electricians, commercial Electricians, licensed contractors, emergency Electricians, Electrical Contractors, Electric Repairs, Electrical Problems',
      title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      canonical: 'https://www.callapro.com/elb//'
    }
  },
  {
    id: 5000,
    name: 'Electricians',
    name_singular: 'Electrician',
    name_adjective: 'Electrical',
    name_plural: 'Electricians',
    name_possessive: "Electrician's",
    parent_id: 100,
    phone_number: 8776074726,
    sms_number: 8559561418,
    showPhoneNumber: true,
    url_slug: 'electrical-services',
    headline: 'Electrical Services',
    modal_title: 'Find an Electrician Fast.',
    modal_title_success: "We've found an Electrician!",
    sms_headline: "CallAPro - We've got an electrician waiting for your call.",
    subheadline: 'We service {{City|your area}} and surrounding cities. Same-day service available. Call <a href="tel:8776074726">(877) 607-4726</a> to schedule a service.',
    hideTags: true,
    hide_popular_categories: true,
    use_dark_theme: true,
    exclude_from_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Electricians',
      og_description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      og_title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      og_url: 'https://www.callapro.com/electrician',
      description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      keywords:
        'Electricians, Electrician, find an Electrician, local Electricians, local Electrician, Electric, Electric companies, Electric contractors, residential Electricians, commercial Electricians, licensed contractors, emergency Electricians, Electrical Contractors, Electric Repairs, Electrical Problems',
      title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      canonical: 'https://www.callapro.com/electrician/'
    }
  },
  {
    id: 5000,
    name: 'Electricians',
    name_singular: 'Electrician',
    name_adjective: 'Electrical',
    name_plural: 'Electricians',
    name_possessive: "Electrician's",
    parent_id: 100,
    phone_number: 8776074726,
    sms_number: 8559561418,
    showPhoneNumber: true,
    url_slug: 'electrical-service',
    headline: 'Electrical Service',
    modal_title: 'Find an Electrician Fast.',
    modal_title_success: "We've found an Electrician!",
    sms_headline: "CallAPro - We've got an electrician waiting for your call.",
    subheadline: 'We service {{City|your area}} and surrounding cities. Same-day service available. Call <a href="tel:8776074726">(877) 607-4726</a> to schedule a service.',
    hideTags: true,
    use_caseys_theme: true,
    hide_popular_categories: true,
    use_dark_theme: false,
    exclude_from_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    use_dark_header: true,
    logo_color: 'yellow',
    category_dude_image: 'https://s3.amazonaws.com/callapro.com/electrical-service.webp',
    category_dude_image_fallback: 'https://s3.amazonaws.com/callapro.com/electrical-service.jpg',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Electricians',
      og_description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      og_title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      og_url: 'https://www.callapro.com/electrician',
      description: "Need an Electrician?  Search the Nation's #1 Directory of Local Electricians.  It's Fast, Free and Easy to Use.  Get a Quote from an Electrician Today!",
      keywords:
        'Electricians, Electrician, find an Electrician, local Electricians, local Electrician, Electric, Electric companies, Electric contractors, residential Electricians, commercial Electricians, licensed contractors, emergency Electricians, Electrical Contractors, Electric Repairs, Electrical Problems',
      title: 'Find Local Electricians | #1 Electrician Directory | Get Quotes',
      canonical: 'https://www.callapro.com/electrician/'
    }
  },
  {
    id: 6000,
    name: 'Exterminators',
    name_singular: 'Exterminator',
    name_adjective: 'Pest Control',
    name_plural: 'Exterminators',
    name_possessive: "Exterminator's",
    parent_id: 100,
    phone_number: 8776412485,
    sms_number: 8666912545,
    showPhoneNumber: true,
    url_slug: 'exterminators',
    headline: 'Exterminator Services',
    modal_title: 'Find an Exterminator Fast.',
    modal_title_success: "We've found an Exterminator!",
    sms_headline: "CallAPro - We've got an exterminator waiting for your call.",
    subheadline: 'Exterminate Bugs, Insects, Rodents and More Today!',
    hideTags: true,
    cta_label: 'Get a Free Quote',
    hide_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    price_guide_data: 'exterminators',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Exterminators',
      og_description: "Need an Exterminator? Search our National Directory of Local Exterminators. It's Fast, Free, and Easy to Use.",
      og_title: 'Find Local Exterminators | Exterminator Directory | CallAPro',
      og_url: 'https://www.callapro.com/exterminators',
      description: "Need an Exterminator? Search our National Directory of Local Exterminators. It's Fast, Free, and Easy to Use.",
      keywords: '',
      title: 'Find Local Exterminators | Exterminator Directory | CallAPro',
      canonical: 'https://www.callapro.com/exterminators/'
    }
  },
  {
    id: 6000,
    name: 'Exterminators',
    name_singular: 'Exterminator',
    name_adjective: 'Pest Control',
    name_plural: 'Exterminators',
    name_possessive: "Exterminator's",
    parent_id: 100,
    phone_number: 8552106571,
    sms_number: 8666912545,
    showPhoneNumber: true,
    url_slug: 'ex',
    headline: 'Exterminator Services',
    modal_title: 'Find an Exterminator Fast.',
    modal_title_success: "We've found an Exterminator!",
    sms_headline: "CallAPro - We've got an exterminator waiting for your call.",
    subheadline: 'Exterminate Bugs, Insects, Rodents and More Today!',
    hideTags: true,
    cta_label: 'Get a Free Quote',
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Exterminators',
      og_description: "Need an Exterminator? Search our National Directory of Local Exterminators. It's Fast, Free, and Easy to Use.",
      og_title: 'Find Local Exterminators | Exterminator Directory | CallAPro',
      og_url: 'https://www.callapro.com/ex',
      description: "Need an Exterminator? Search our National Directory of Local Exterminators. It's Fast, Free, and Easy to Use.",
      keywords: '',
      title: 'Find Local Exterminators | Exterminator Directory | CallAPro',
      canonical: 'https://www.callapro.com/ex/'
    }
  },
  {
    id: 173,
    name: 'Locksmiths',
    name_singular: 'Locksmith',
    name_adjective: 'Locksmith',
    name_plural: 'Locksmiths',
    name_possessive: "Locksmith's",
    parent_id: 12047,
    phone_number: 8775471950,
    url_slug: 'locksmiths',
    headline: 'Find a Locksmith Fast!',
    modal_title: 'Find a Locksmith Fast.',
    modal_title_success: "We've found a Locksmith to Help You!",
    sms_headline: "CallAPro - We've got a locksmith waiting for your call.",
    subheadline: 'Get connected to a local locksmith right when you need them.',
    hideTags: true,
    price_guide_data: 'locksmiths',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Locksmiths',
      og_description:
        'From locking your keys in your car to rekeying the locks on your new home, locksmith services can be a lifesaver and CallAPro can help. Here’s how much these miracle-workers charge for their services.',
      og_title: 'Local Locksmiths | CallAPro.com',
      og_url: 'https://www.callapro.com/locksmiths',
      description:
        'From locking your keys in your car to rekeying the locks on your new home, locksmith services can be a lifesaver and CallAPro can help. Here’s how much these miracle-workers charge for their services.',
      keywords: 'Locksmiths, listings, national',
      title: 'Local Locksmiths | CallAPro.com',
      canonical: 'https://www.callapro.com/locksmiths/'
    }
  },
  {
    id: 17,
    category_search_id: 584,
    name: 'HVAC Contractors',
    name_singular: 'HVAC Contractor',
    name_adjective: 'HVAC',
    name_plural: 'HVAC Contractors',
    name_possessive: "HVAC Contractor's",
    parent_id: 100,
    phone_number: 8882985435,
    sms_number: 8885077102,
    showPhoneNumber: true,
    url_slug: 'heating-air-conditioning',
    headline: 'Find an HVAC Technician Fast!',
    modal_title: 'Find an HVAC Technician Fast.',
    modal_title_success: "We've found an HVAC Technician!",
    sms_headline: "CallAPro - We've got an HVAC technician waiting for your call.",
    subheadline: 'Get connected to a local HVAC professional right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'HVAC Contractors',
      og_description: "Looking for HVAC Contractors? Search Our National Directory of Local HVAC Contractors. It's Fast, Free, and Easy to Use.",
      og_title: 'Local HVAC Contractors | CallAPro.com',
      og_url: 'https://www.callapro.com/heating-air-conditioning',
      description: "Looking for HVAC Contractors? Search Our National Directory of Local HVAC Contractors. It's Fast, Free, and Easy to Use.",
      keywords: 'HVAC Contractors, listings, national',
      title: 'Local HVAC Contractors | CallAPro.com',
      canonical: 'https://www.callapro.com/heating-air-conditioning/'
    }
  },
  {
    id: 149,
    name: 'Appliance Repair',
    name_singular: 'Appliance Repair Pro',
    name_adjective: 'Appliance Repair',
    name_plural: 'Appliance Repair Pros',
    name_possessive: "Appliance Repair Pro's",
    parent_id: 100,
    phone_number: 8884763651,
    sms_number: 8443264998,
    showPhoneNumber: true,
    url_slug: 'appliance-installation-repair',
    headline: 'Get your Appliance Repaired Fast!',
    modal_title: 'Find an Appliance Repair Expert Fast.',
    modal_title_success: "We've found an Appliance Repair Expert!",
    sms_headline: "CallAPro - We've got an Appliance Repair pro waiting for your call.",
    subheadline: 'Get connected to a local Appliance Repair pro right when you need them.',
    cta_copy: 'Schedule Repair',
    hideTags: true,
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Appliance Repair Contractors',
      og_description: "Looking for Appliance Repair? Search Our National Directory of Local Appliance Repair. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Appliance Repair Contractors | CallAPro.com',
      og_url: 'https://www.callapro.com/appliance-installation-repair',
      description: "Looking for Appliance Repair? Search Our National Directory of Local Appliance Repair. It's Fast, Free, and Easy to Use.",
      keywords: 'Appliance Repair, listings, national',
      title: 'Local Appliance Repair Contractors | CallAPro.com',
      canonical: 'https://www.callapro.com/appliance-installation-repair/'
    }
  },
  {
    id: 149,
    name: 'Appliance Repair',
    name_singular: 'Appliance Repair Pro',
    name_adjective: 'Appliance Repair',
    name_plural: 'Appliance Repair Pros',
    name_possessive: "Appliance Repair Pro's",
    parent_id: 100,
    phone_number: 8884763651,
    sms_number: 8443264998,
    showPhoneNumber: true,
    url_slug: 'appliance-repair',
    headline: 'Appliance Repair',
    modal_title: 'Find an Appliance Repair Expert Fast.',
    modal_title_success: "We've found an Appliance Repair Expert!",
    sms_headline: "CallAPro - We've got an Appliance Repair pro waiting for your call.",
    subheadline:
      'We service {{City|your area}} and surrounding neighborhoods. Repairs and installation. Same-day service available. Call <a href="tel:8334572225">(833) 457-2225</a> to schedule service.',
    cta_copy: 'Schedule Repair',
    hideTags: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Appliance Repair Contractors',
      og_description: "Looking for Appliance Repair? Search Our National Directory of Local Appliance Repair. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Appliance Repair Contractors | CallAPro.com',
      og_url: 'https://www.callapro.com/appliance-repair',
      description: "Looking for Appliance Repair? Search Our National Directory of Local Appliance Repair. It's Fast, Free, and Easy to Use.",
      keywords: 'Appliance Repair, listings, national',
      title: 'Local Appliance Repair Contractors | CallAPro.com',
      canonical: 'https://www.callapro.com/appliance-repair/'
    }
  },
  {
    id: 517,
    name: 'Auto Glass',
    name_singular: 'Auto Glass Pro',
    name_adjective: 'Auto Glass',
    name_plural: 'Auto Glass Pro',
    name_possessive: "Auto Glass Pro's",
    parent_id: 1,
    phone_number: 8778831723,
    url_slug: 'auto-glass',
    headline: 'Find an Auto Glass Specialist Today!',
    modal_title: 'Find an Auto Glass Technician Fast.',
    modal_title_success: "We've found an Auto Glass Technician!",
    sms_headline: "CallAPro - We've got an auto glass technician waiting for your call.",
    subheadline: 'Get connected to a local auto glass technician right when you need them.',
    hideTags: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Auto Glass Technicians',
      og_description: "Looking for Auto Glass? Search Our National Directory of Local Auto Glass. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Auto Glass Technicians | CallAPro.com',
      og_url: 'https://www.callapro.com/auto-glass',
      description: "Looking for Auto Glass? Search Our National Directory of Local Auto Glass. It's Fast, Free, and Easy to Use.",
      keywords: 'Auto Glass, listings, national',
      title: 'Local Auto Glass Technicians | CallAPro.com',
      canonical: 'https://www.callapro.com/auto-glass/'
    }
  },
  {
    id: 354,
    name: 'Water & Fire Damage',
    name_singular: 'Water & Fire Damage Pro',
    name_adjective: 'Water & Fire Damage',
    name_plural: 'Water & Fire Damage Pros',
    name_possessive: "Water & Fire Damage Pro's",
    parent_id: 100,
    phone_number: 8775260343,
    url_slug: 'water-fire-damage-restoration',
    headline: 'Find Water & Fire Damage Service Fast!',
    modal_title: 'Find Water & Fire Damage Service Fast.',
    modal_title_success: "We've found a Water & Fire Damage Service Professional!",
    sms_headline: "CallAPro - We've got an water & fire damage service professional waiting for your call.",
    subheadline: 'Get connected to a local water & fire damage service professional right when you need them.',
    hideTags: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Water and Fire Damage Service Providers',
      og_description: "Looking for Water and Fire Damage Service Providers? Search Our National Directory of Local Water and Fire Damage Restoration. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Water and Fire Damage Service Providers | CallAPro.com',
      og_url: 'https://www.callapro.com/water-fire-damage-restoration',
      description: "Looking for Water and Fire Damage Service Providers? Search Our National Directory of Local Water and Fire Damage Restoration. It's Fast, Free, and Easy to Use.",
      keywords: 'Water and Fire Damage Service Providers, listings, national',
      title: 'Local Water and Fire Damage Service Providers | CallAPro.com',
      canonical: 'https://www.callapro.com/water-fire-damage-restoration/'
    }
  },
  {
    id: 175,
    name: 'Mold Remediation',
    name_singular: 'Mold Remediator Pro',
    name_adjective: 'Mold Remediation',
    name_plural: 'Mold Remediator Pros',
    name_possessive: "Mold Remediator Pro's",
    parent_id: 100,
    phone_number: 8773958236,
    sms_number: 8669124525,
    showPhoneNumber: true,
    url_slug: 'mold-remediation',
    headline: 'Mold Remediators',
    modal_title: 'Find a Mold Remediation Expert Fast.',
    modal_title_success: "We've found a Mold Remediation Expert!",
    sms_headline: "CallAPro - We've got a mold remediation expert waiting for your call.",
    subheadline: 'Find a Pro, Get a Free Quote, Get Mold Remediation Today!',
    hideTags: true,
    cta_label: 'Get a Free Quote',
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Mold Remediation Experts',
      og_description: "Looking for Mold Remediation Experts? Search Our National Directory of Local Mold Remediation Experts. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Mold Remediation Experts | CallAPro.com',
      og_url: 'https://www.callapro.com/mold-remediation',
      description: "Looking for Mold Remediation Experts? Search Our National Directory of Local Mold Remediation Experts. It's Fast, Free, and Easy to Use.",
      keywords: 'Mold Remediation Experts, listings, national',
      title: 'Local Mold Remediation Experts | CallAPro.com',
      canonical: 'https://www.callapro.com/mold-remediation/'
    }
  },
  {
    id: 249,
    name: 'Bathroom Remodeling',
    name_singular: 'Bathroom Remodeler',
    name_adjective: 'Bathroom',
    name_plural: 'Bathroom Remodelers',
    name_possessive: "Bathroom Remodeling Pro's",
    parent_id: 100,
    phone_number: 8778100728,
    url_slug: 'bathroom-remodeling-contractors',
    headline: 'Remodel Your Bathroom Today!',
    modal_title: 'Find a Remodeling Contractor Fast.',
    modal_title_success: "We've found a Remodeling Contractor!",
    sms_headline: "CallAPro - We've got a remodeling contractor waiting for your call.",
    subheadline: 'Get connected to a local remodeling contractor right when you need them.',
    hideTags: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Bathroom Remodelers',
      og_description: "Looking for Bathroom Remodelers? Search Our National Directory of Local Bathroom Remodelers. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Bathroom Remodelers | CallAPro.com',
      og_url: 'https://www.callapro.com/bathroom-remodeling-contractors',
      description: "Looking for Bathroom Remodelers? Search Our National Directory of Local Bathroom Remodelers. It's Fast, Free, and Easy to Use.",
      keywords: 'Bathroom Remodelers, listings, national',
      title: 'Local Bathroom Remodelers | CallAPro.com',
      canonical: 'https://www.callapro.com/bathroom-remodeling-contractors/'
    }
  },
  {
    id: 24,
    name: 'Painters',
    name_singular: 'Painter',
    name_adjective: 'Painting',
    name_plural: 'Painters',
    name_possessive: "Painter's",
    parent_id: 100,
    phone_number: 8778831723,
    url_slug: 'painters',
    headline: 'Find a Painter Fast!',
    modal_title: 'Find a Painter Fast.',
    modal_title_success: "We've found a Painter!",
    sms_headline: "CallAPro - We've got a painter contractor waiting for your call.",
    subheadline: 'Get connected to a local painter right when you need them.',
    hideTags: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Painters',
      og_description: "Looking for Painters? Search Our National Directory of Local Painters. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Painters | CallAPro.com',
      og_url: 'https://www.callapro.com/painters',
      description: "Looking for Painters? Search Our National Directory of Local Painters. It's Fast, Free, and Easy to Use.",
      keywords: 'Painters, listings, national',
      title: 'Local Painters | CallAPro.com',
      canonical: 'https://www.callapro.com/painters/'
    }
  },
  {
    id: 584,
    name: 'AC Contractors',
    name_singular: 'A/C Contractor',
    name_adjective: 'A/C',
    name_plural: 'A/C Contractors',
    name_possessive: "A/C Contractor's",
    parent_id: 17,
    phone_number: 8882985435,
    sms_number: 8885077102,
    showPhoneNumber: true,
    url_slug: 'air-conditioning-contractors',
    headline: 'Get A/C Help Fast!',
    modal_title: 'Find an A/C Professional Fast.',
    modal_title_success: "We've found an A/C Professional!",
    sms_headline: "CallAPro - We've got an A/C professional waiting for your call.",
    subheadline: 'Get connected to a local A/C professional right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Air Conditioning Contractors',
      og_description: "Looking for Air Conditioning Contractors? Search Our National Directory of Local Air Conditioning Contractors. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Air Conditioning Contractors | CallAPro.com',
      og_url: 'https://www.callapro.com/air-conditioning-contractors',
      description: "Looking for Air Conditioning Contractors? Search Our National Directory of Local Air Conditioning Contractors. It's Fast, Free, and Easy to Use.",
      keywords: 'Air Conditioning Contractors, listings, national',
      title: 'Local Air Conditioning Contractors | CallAPro.com',
      canonical: 'https://www.callapro.com/air-conditioning-contractors/'
    }
  },
  {
    id: 583,
    name: 'Heating Contractors',
    name_singular: 'Heating Contractor',
    name_adjective: 'Heating',
    name_plural: 'Heating Contractors',
    name_possessive: "Heating Contractor's",
    parent_id: 17,
    phone_number: 8882985435,
    sms_number: 8885077102,
    showPhoneNumber: true,
    url_slug: 'heating-contractors',
    headline: 'Find Your Heating Contractor Now!',
    modal_title: 'Find a Heating Contractor Fast.',
    modal_title_success: "We've found a Heating Contractor!",
    sms_headline: "CallAPro - We've got a heating contractor waiting for your call.",
    subheadline: 'Get connected to a local heating contractor right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Heating Contractors',
      og_description: "Looking for Heating Contractors? Search Our National Directory of Local Heating Contractors. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Heating Contractors | CallAPro.com',
      og_url: 'https://www.callapro.com/heating-contractors',
      description: "Looking for Heating Contractors? Search Our National Directory of Local Heating Contractors. It's Fast, Free, and Easy to Use.",
      keywords: 'Heating Contractors, listings, national',
      title: 'Local Heating Contractors | CallAPro.com',
      canonical: 'https://www.callapro.com/heating-contractors/'
    }
  },
  {
    id: 160,
    name: 'Window Installers',
    name_singular: 'Window Installer',
    name_adjective: 'Window',
    name_plural: 'Window Installation Pros',
    name_possessive: "Window Installation Pro's",
    parent_id: 100,
    phone_number: 8778831723,
    url_slug: 'windows',
    headline: 'Find a Window Installer Fast!',
    modal_title: 'Find a Window Installer Fast.',
    modal_title_success: "We've found a Window Installer!",
    sms_headline: "CallAPro - We've got a window installer waiting for your call.",
    subheadline: 'Get connected to a local window installer right when you need them.',
    hideTags: true,
    hide_popular_categories: true,
    showPhoneNumber: true,
    use_custom_question_flow: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Window Installers',
      og_description: "Looking for Window Installers? Search Our National Directory of Local Window Installers. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Window Installers | CallAPro.com',
      og_url: 'https://www.callapro.com/windows',
      description: "Looking for Window Installers? Search Our National Directory of Local Window Installers. It's Fast, Free, and Easy to Use.",
      keywords: 'Window Installers, listings, national',
      title: 'Local Window Installers | CallAPro.com',
      canonical: 'https://www.callapro.com/windows/'
    }
  },
  {
    id: 6000,
    name: 'Flea Exterminators',
    name_singular: 'Flea Exterminator',
    name_adjective: 'Pest Control',
    name_plural: 'Flea Exterminators',
    name_possessive: "Flea Exterminator's",
    parent_id: 100,
    phone_number: 8776412485,
    sms_number: 8666912545,
    showPhoneNumber: true,
    url_slug: 'flea-exterminators',
    headline: 'Flea Exterminator Services',
    modal_title: 'Find a Flea Exterminator Fast.',
    modal_title_success: "We've found a Flea Exterminator!",
    sms_headline: "CallAPro - We've got a flea exterminator waiting for your call.",
    subheadline: 'Exterminate Fleas Today!',
    hideTags: true,
    cta_label: 'Get a Free Quote',
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Flea Exterminators',
      og_description: "Need a Flea Exterminator? Search our National Directory of Local Flea Exterminators. It's Fast, Free, and Easy to Use.",
      og_title: 'Find Local Flea Exterminators | Flea Exterminator Directory | CallAPro',
      og_url: 'https://www.callapro.com/flea-exterminators',
      description: "Need a Flea Exterminator? Search our National Directory of Local Flea Exterminators. It's Fast, Free, and Easy to Use.",
      keywords: '',
      title: 'Find Local Flea Exterminators | Flea Exterminator Directory | CallAPro',
      canonical: 'https://www.callapro.com/flea-exterminators/'
    }
  },
  {
    id: 251,
    name: 'Home Remodeling',
    name_singular: 'Home Remodeler',
    name_adjective: 'Home',
    name_plural: 'Home Remodeling Pros',
    name_possessive: "Home Remodeling Pro's",
    parent_id: 100,
    phone_number: 8775574294,
    url_slug: 'home-remodeling-contractors',
    headline: 'Remodel Your Home Today!',
    modal_title: 'Find a Home Remodeling Contractor Fast.',
    modal_title_success: "We've found a Home Remodeling Contractor!",
    sms_headline: "CallAPro - We've got a home remodeling contractor waiting for your call.",
    subheadline: 'Get connected to a local home remodeling contractor right when you need them.',
    hideTags: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Home Remodeling',
      og_description: "Looking for Home Remodelers? Search Our National Directory of Home Remodelers. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Home Remodeling | Home Remodeling Directory | CallAPro',
      og_url: 'https://www.callapro.com/home-remodeling-contractors',
      description: "Looking for Home Remodelers? Search Our National Directory of Home Remodelers. It's Fast, Free, and Easy to Use.",
      keywords: 'Home Remodeling, listings, national',
      title: 'Local Home Remodeling | CallAPro.com',
      canonical: 'https://www.callapro.com/home-remodeling-contractors/'
    }
  },
  {
    id: 20,
    name: 'Landscapers',
    name_singular: 'Landscaper',
    name_adjective: 'Landscaping',
    name_plural: 'Landscapers',
    name_possessive: "Landscaper's",
    parent_id: 100,
    phone_number: 8779595413,
    url_slug: 'landscapers',
    headline: 'Find a Landscaper Fast!',
    modal_title: 'Find a Landscaper Fast.',
    modal_title_success: "We've found a Landscaper!",
    sms_headline: "CallAPro - We've got a Landscaper waiting for your call.",
    subheadline: 'Get connected to a local Landscaper right when you need them.',
    hideTags: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Landscapers',
      og_description: "Looking for Landscapers? Search Our National Directory of Local Landscapers. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Landscapers | CallAPro.com',
      og_url: 'https://www.callapro.com/landscaping',
      description: "Looking for Landscapers? Search Our National Directory of Local Landscapers. It's Fast, Free, and Easy to Use.",
      keywords: 'Landscapers, listings, national',
      title: 'Local Landscapers | CallAPro.com',
      canonical: 'https://www.callapro.com/landscaping/'
    }
  },
  {
    id: 483,
    name: 'Solar Installation',
    name_singular: 'Solar Installer',
    name_adjective: 'Solar',
    name_plural: 'Solar Installation Pros',
    name_possessive: "Solar Installation Pro's",
    parent_id: 100,
    phone_number: 8669881308,
    sms_number: 8669103073,
    showPhoneNumber: true,
    url_slug: 'solar-installation',
    headline: 'Solar Installation Experts',
    modal_title: 'Find a Solar Installation Pro Fast.',
    modal_title_success: "We've found a Solar Installation Pro!",
    sms_headline: "CallAPro - We've got a solar installation pro waiting for your call.",
    subheadline: 'Find a Pro, Get a Free Quote, Get Solar!',
    hideTags: true,
    cta_label: 'Get a Free Quote',
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Solar Installation',
      og_description: '',
      og_title: 'Local Solar Installation | CallAPro.com',
      og_url: 'https://www.callapro.com/solar-installation',
      description: "Looking for Solar Installation? Search Our National Directory of Local Solar Installation. It's Fast, Free, and Easy to Use.",
      keywords: 'Solar Installation, listings, national',
      title: 'Local Solar Installation | CallAPro.com',
      canonical: 'https://www.callapro.com/solar-installation/'
    },
    meta_srp_zip: {
      page_name: 'Listings-Zip',
      og_title: 'Solar Installation in {{zip_code}} | CallAPro.com',
      description: 'Find {{city, st}} Solar Installation in {{zip_code}} | CallAPro.com',
      title: 'Solar Installation in {{zip_code}} | CallAPro.com'
    },
    meta_srp_city: {
      page_name: 'Listings-City',
      og_title: 'Find Top Rated Solar Installation in {{city}} | CallAPro.com',
      description: 'Choose the most trusted Solar Installation in {{city}} at CallAPro.com. Use our fast and free directory to find a local Solar Installation near you.',
      title: 'Find Top Rated Solar Installation in {{city}} | CallAPro.com'
    }
  },
  {
    id: 164,
    name: 'Tree Service',
    name_singular: 'Tree Service Pro',
    name_adjective: 'Tree Servicing',
    name_plural: 'Tree Service Pros',
    name_possessive: "Tree Service Pro's",
    parent_id: 100,
    phone_number: 8775874642,
    sms_number: 8666206266,
    showPhoneNumber: true,
    url_slug: 'tree-service',
    headline: 'Tree Service Experts',
    modal_title: 'Find a Tree Service Pro Fast.',
    modal_title_success: "We've found a Tree Service Pro!",
    sms_headline: "CallAPro - We've got a tree service pro waiting for your call.",
    subheadline: 'Find a Pro, Get a Free Quote, Get Tree Service Today!',
    hideTags: true,
    cta_label: 'Get a Free Quote',
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Tree Service',
      og_description: '',
      og_title: 'Local Tree Service | CallAPro.com',
      og_url: 'https://www.callapro.com/tree-service',
      description: "Looking for Tree Service? Search Our National Directory of Local Tree Service. It's Fast, Free, and Easy to Use.",
      keywords: 'Tree Service, listings, national',
      title: 'Local Tree Service | CallAPro.com',
      canonical: 'https://www.callapro.com/tree-service/'
    },
    meta_srp_zip: {
      page_name: 'Listings-Zip',
      og_title: 'Tree Service in {{zip_code}} | CallAPro.com',
      description: 'Find {{city, st}} Tree Service in {{zip_code}} | CallAPro.com',
      title: 'Tree Service in {{zip_code}} | CallAPro.com'
    },
    meta_srp_city: {
      page_name: 'Listings-City',
      og_title: 'Find Top Rated Tree Service in {{city}} | CallAPro.com',
      description: 'Choose the most trusted Tree Service in {{city}} at CallAPro.com. Use our fast and free directory to find a local Tree Service near you.',
      title: 'Find Top Rated Tree Service in {{city}} | CallAPro.com'
    }
  },
  {
    id: 177,
    name: 'Siding Contractors',
    name_singular: 'Siding Contractor',
    name_adjective: 'Siding',
    name_plural: 'Siding Contractors',
    name_possessive: "Siding Contractor's",
    parent_id: 100,
    phone_number: 8668617712,
    sms_number: 665123310,
    showPhoneNumber: true,
    url_slug: 'siding-contractors',
    headline: 'Siding Contractors',
    modal_title: 'Find a Siding Contractor Fast.',
    modal_title_success: "We've found a Siding Contractor!",
    sms_headline: "CallAPro - We've got a siding contractor waiting for your call.",
    subheadline: 'Find a Pro, Get a Free Quote, Get Siding Today!',
    hideTags: true,
    cta_label: 'Get a Free Quote',
    hide_popular_categories: true,
    meta: {
      author: 'CallAPro.com',
      category_name: 'Siding Contractors',
      og_description: '',
      og_title: 'Local Siding Contractors | CallAPro.com',
      og_url: 'https://www.callapro.com/siding-contractors',
      description: "Looking for Siding Contractors? Search Our National Directory of Local Siding Contractors. It's Fast, Free, and Easy to Use.",
      keywords: 'Siding Contractors, listings, national',
      title: 'Local Siding Contractors | CallAPro.com',
      canonical: 'https://www.callapro.com/siding-contractors/'
    },
    meta_srp_zip: {
      page_name: 'Listings-Zip',
      og_title: 'Siding Contractors in {{zip_code}} | CallAPro.com',
      description: 'Find {{city, st}} Siding Contractors in {{zip_code}} | CallAPro.com',
      title: 'Siding Contractors in {{zip_code}} | CallAPro.com'
    },
    meta_srp_city: {
      page_name: 'Listings-City',
      og_title: 'Top Rated Siding Contractors in {{city}} | CallAPro.com',
      description: 'Contact the most reliable Siding Contractors in {{city}} at CallAPro.com. Use our fast and easy directory to find a local Siding Contractors near you.',
      title: 'Top Rated Siding Contractors in {{city}} | CallAPro.com'
    }
  },
  {
    id: 10000,
    name: 'Drains',
    name_singular: 'Drain',
    name_adjective: 'Plumbing',
    name_plural: 'Plumbers',
    name_possessive: "Plumber's",
    parent_id: 100,
    phone_number: 8667206270,
    sms_number: 8886047504,
    showPhoneNumber: true,
    url_slug: 'drains',
    headline: 'Get Drain Help Fast!',
    modal_title: 'Find a Plumber Fast.',
    modal_title_success: "We've found a Plumber!",
    sms_headline: "CallAPro - We've got a plumber waiting for your call.",
    subheadline: 'Get connected to a local plumbing professional right when you need them.',
    hideTags: true,
    show_lead_form_after_profindr_flow: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_phone_in_header: true,
    price_guide_label: 'Plumbers',
    articles_category_name: 'Plumbers',
    articles_category_name_singular: 'Plumber',
    articles_url_slug: 'plumbers',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Plumbers',
      og_description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      og_title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      og_url: 'https://www.callapro.com/drains',
      description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      keywords:
        'Plumbers Directory, Find A Local Plumber, The 1 Plumbing Site, plumbers, plumber, find a plumber, local plumbers, local plumber, plumbing, plumbing companies, plumbing contractors, residential plumbers, commercial plumbers, licensed contractors, sewer drain cleaning contractors, emergency plumbers, Plumbing Problems and Repairs',
      title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      canonical: 'https://www.callapro.com/drains/'
    }
  },
  {
    id: 10000,
    name: 'Garbage Disposal',
    name_singular: 'Garbage Disposal',
    name_adjective: 'Plumbing',
    name_plural: 'Plumbers',
    name_possessive: "Plumber's",
    parent_id: 100,
    phone_number: 8667206270,
    sms_number: 8886047504,
    showPhoneNumber: true,
    url_slug: 'garbage-disposal',
    headline: 'Get Garbage Disposal Help Fast!',
    modal_title: 'Find a Plumber Fast.',
    modal_title_success: "We've found a Plumber!",
    sms_headline: "CallAPro - We've got a plumber waiting for your call.",
    subheadline: 'Get connected to a local plumbing professional right when you need them.',
    hideTags: true,
    show_lead_form_after_profindr_flow: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_phone_in_header: true,
    price_guide_label: 'Plumbers',
    articles_category_name: 'Plumbers',
    articles_category_name_singular: 'Plumber',
    articles_url_slug: 'plumbers',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Plumbers',
      og_description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      og_title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      og_url: 'https://www.callapro.com/garbage-disposal',
      description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      keywords:
        'Plumbers Directory, Find A Local Plumber, The 1 Plumbing Site, plumbers, plumber, find a plumber, local plumbers, local plumber, plumbing, plumbing companies, plumbing contractors, residential plumbers, commercial plumbers, licensed contractors, sewer drain cleaning contractors, emergency plumbers, Plumbing Problems and Repairs',
      title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      canonical: 'https://www.callapro.com/garbage-disposal/'
    }
  },
  {
    id: 10000,
    name: 'Gas Lines',
    name_singular: 'Gas Line',
    name_adjective: 'Plumbing',
    name_plural: 'Plumbers',
    name_possessive: "Plumber's",
    parent_id: 100,
    phone_number: 8667206270,
    sms_number: 8886047504,
    showPhoneNumber: true,
    url_slug: 'plumbers-gas-lines',
    headline: 'Gas Line Services',
    modal_title: 'Find a Plumber Fast.',
    modal_title_success: "We've found a Plumber!",
    sms_headline: "CallAPro - We've got a plumber waiting for your call.",
    subheadline: 'We service {{City|your area}} and surrounding cities. Same-day service. Call <a href="tel:8667206270">(866) 720-6270</a> to schedule your plumber.',
    hideTags: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_phone_in_header: true,
    gtag_phone_config_id: 'AW-11164146131/xf1ACMaphdAYENOzvcsp',
    show_lead_form_after_profindr_flow: true,
    price_guide_data: 'plumbers',
    articles_category_name: 'Plumbers',
    articles_category_name_singular: 'Plumber',
    articles_url_slug: 'plumbers',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Plumbers',
      og_description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      og_title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      og_url: 'https://www.callapro.com/plumbers-gas-lines',
      description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      keywords:
        'Plumbers Directory, Find A Local Plumber, The 1 Plumbing Site, plumbers, plumber, find a plumber, local plumbers, local plumber, plumbing, plumbing companies, plumbing contractors, residential plumbers, commercial plumbers, licensed contractors, sewer drain cleaning contractors, emergency plumbers, Plumbing Problems and Repairs',
      title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      canonical: 'https://www.callapro.com/plumbers-gas-lines/'
    }
  },
  {
    id: 10000,
    name: 'Water Heaters',
    name_singular: 'Water Heater',
    name_adjective: 'Plumbing',
    name_plural: 'Plumbers',
    name_possessive: "Plumber's",
    parent_id: 100,
    phone_number: 8667206270,
    sms_number: 8886047504,
    showPhoneNumber: true,
    url_slug: 'plumbers-water-heaters',
    headline: 'Water Heater Services',
    modal_title: 'Find a Plumber Fast.',
    modal_title_success: "We've found a Plumber!",
    sms_headline: "CallAPro - We've got a plumber waiting for your call.",
    subheadline: 'Repair & Installation. We service {{City|your area}} and surrounding cities. Same-day service. Call <a href="tel:8667206270">(866) 720-6270</a> to schedule your plumber.',
    hideTags: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_phone_in_header: true,
    gtag_phone_config_id: 'AW-11164146131/xf1ACMaphdAYENOzvcsp',
    show_lead_form_after_profindr_flow: true,
    price_guide_data: 'plumbers',
    articles_category_name: 'Plumbers',
    articles_category_name_singular: 'Plumber',
    articles_url_slug: 'plumbers',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Plumbers',
      og_description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      og_title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      og_url: 'https://www.callapro.com/plumbers-water-heaters',
      description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      keywords:
        'Plumbers Directory, Find A Local Plumber, The 1 Plumbing Site, plumbers, plumber, find a plumber, local plumbers, local plumber, plumbing, plumbing companies, plumbing contractors, residential plumbers, commercial plumbers, licensed contractors, sewer drain cleaning contractors, emergency plumbers, Plumbing Problems and Repairs',
      title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      canonical: 'https://www.callapro.com/plumbers-water-heaters/'
    }
  },
  {
    id: 10000,
    name: 'Toilets',
    name_singular: 'Toilet',
    name_adjective: 'Plumbing',
    name_plural: 'Plumbers',
    name_possessive: "Plumber's",
    parent_id: 100,
    phone_number: 8667206270,
    sms_number: 8886047504,
    showPhoneNumber: true,
    url_slug: 'plumbers-toilets',
    headline: 'Toilet Services',
    modal_title: 'Find a Plumber Fast.',
    modal_title_success: "We've found a Plumber!",
    sms_headline: "CallAPro - We've got a plumber waiting for your call.",
    subheadline: 'Repair & Installation. We service {{City|your area}} and surrounding cities. Same-day service. Call <a href="tel:8667206270">(866) 720-6270</a> to schedule your plumber.',
    hideTags: true,
    hide_popular_categories: true,
    exclude_from_popular_categories: true,
    show_phone_in_header: true,
    gtag_phone_config_id: 'AW-11164146131/xf1ACMaphdAYENOzvcsp',
    show_lead_form_after_profindr_flow: true,
    price_guide_data: 'plumbers',
    articles_category_name: 'Plumbers',
    articles_category_name_singular: 'Plumber',
    articles_url_slug: 'plumbers',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Plumbers',
      og_description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      og_title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      og_url: 'https://www.callapro.com/plumbers-toilets',
      description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      keywords:
        'Plumbers Directory, Find A Local Plumber, The 1 Plumbing Site, plumbers, plumber, find a plumber, local plumbers, local plumber, plumbing, plumbing companies, plumbing contractors, residential plumbers, commercial plumbers, licensed contractors, sewer drain cleaning contractors, emergency plumbers, Plumbing Problems and Repairs',
      title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      canonical: 'https://www.callapro.com/plumbers-toilets/'
    }
  },
  {
    id: 10000,
    name: 'Plumbers',
    name_singular: 'Plumber',
    name_adjective: 'Plumbing',
    name_plural: 'Plumbers',
    name_possessive: "Plumber's",
    parent_id: 100,
    phone_number: 8667206270,
    sms_number: 8886047504,
    showPhoneNumber: true,
    url_slug: 'plumbing-service',
    headline: 'Plumbing Pros',
    modal_title: 'Find a Plumber Fast.',
    modal_title_success: "We've found a Plumber!",
    sms_headline: "CallAPro - We've got a plumber waiting for your call.",
    subheadline: 'Get help fast. We service {{City|your area}} and surrounding cities. Same-day service. Call <a href="tel:8667206270">(866) 720-6270</a> to schedule your plumber.',
    hideTags: true,
    use_caseys_theme: true,
    use_caseys_theme_blue_button: true,
    hide_popular_categories: true,
    use_dark_theme: false,
    exclude_from_popular_categories: true,
    show_lead_form_after_profindr_flow: true,
    use_dark_header: true,
    category_dude_image: 'https://s3.amazonaws.com/callapro.com/plumbing-service.webp',
    category_dude_image_fallback: 'https://s3.amazonaws.com/callapro.com/plumbing-service.jpg',
    meta: {
      author: 'CallAPro.com',
      category_name: 'Plumbers',
      og_description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      og_title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      og_url: 'https://www.callapro.com/plumbing-service',
      description: "Need a Plumber?  Search the Nation's #1 Directory of Local Plumbers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Plumber Today!",
      keywords:
        'Plumbers Directory, Find A Local Plumber, The 1 Plumbing Site, plumbers, plumber, find a plumber, local plumbers, local plumber, plumbing, plumbing companies, plumbing contractors, residential plumbers, commercial plumbers, licensed contractors, sewer drain cleaning contractors, emergency plumbers, Plumbing Problems and Repairs',
      title: 'Find Local Plumbers | #1 Plumber Directory | Get Plumbing Quotes',
      canonical: 'https://www.callapro.com/plumbing-service/'
    }
  }
];
