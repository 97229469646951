declare let gtag;

import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Inter, PT_Serif, Barlow, Merriweather } from 'next/font/google';
import { appWithTranslation } from 'next-i18next';

import { trackEvent } from '@/helpers/helpers_tracking';
import { SiteContextProvider } from '@/contexts/site-context';
import AbandonFlowModal from '@/components/AbandonFlowModal';

import '@/styles/globals.scss';
import '@/styles/form.scss';
import '@/styles/signup-form.scss';
import '@/styles/summit.scss';

const Script = dynamic(() => import('script-tag-in-react'), { ssr: false });

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof window !== 'undefined' && typeof url !== 'undefined') {
      window.location = url;
    }
  };

  // Replace the following with your actual gtag function or include the necessary script.
  gtag('event', 'conversion', {
    send_to: 'AW-11164146131/mkyWCOz_ovcYENOzvcsp',
    value: 10.0,
    currency: 'USD',
    event_callback: callback
  });
};

const handlePhoneLinkClick = (event) => {
  const phoneNumberLink = event.target.closest('a[href^="tel:"]');

  if (phoneNumberLink) {
    event.preventDefault();
    gtag_report_conversion(phoneNumberLink.getAttribute('href'));
  }
};

const inter = Inter({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600', '700', '800', '900'],
  variable: '--font-inter'
});

const pt_serif = PT_Serif({
  subsets: ['latin'],
  weight: ['400', '700'],
  variable: '--font-pt_serif'
});

const barlow = Barlow({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  variable: '--font-barlow'
});

const merriweather = Merriweather({
  subsets: ['latin'],
  weight: ['300', '400', '700', '900'],
  variable: '--font-merriweather'
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const [loadThirdPartyScripts, setLoadThirdPartyScripts] = useState(false);

  useEffect(() => {
    try {
      // Track initial page view
      trackEvent({
        event_type: 'pageView',
        event_category: 'navigation',
        event_action: 'viewPage'
      });
      console.debug('page view tracked');
    } catch (error) {
      console.debug('page view not tracked', error);
    }

    // Track route changes
    const handleRouteChange = () => {
      try {
        trackEvent({
          event_type: 'pageView',
          event_category: 'navigation',
          event_action: 'viewPage'
        });
      } catch (error) {
        console.debug('page view not tracked', error);
      }
    };
    // Attach the event listener
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up the event listener
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Trigger the 'config' event for each new page visited
      gtag('config', 'AW-11164146131', {
        page_path: url
      });
    };

    // Listen for route changes.
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up.
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    window.addEventListener('click', handlePhoneLinkClick);

    return () => {
      window.removeEventListener('click', handlePhoneLinkClick);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadThirdPartyScripts(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <main className={`${inter.variable} ${pt_serif.variable} ${barlow.variable} ${merriweather.variable}`}>
      {loadThirdPartyScripts && (
        <>
          <Script id="google-ads-src" type="text/javascript" getScriptOption="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=AW-11164146131" async defer />

          <Script
            id="counter"
            type="text/javascript"
            getScriptOption="afterInteractive"
            src="https://cdn.counter.dev/script.js"
            data-id="a7f4d051-c06a-4d7c-ae65-d48a558b3051"
            data-utcoffset="-5"
            async
            defer
          />

          <Script
            id="google-analytics"
            type="text/javascript"
            getScriptOption="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-11164146131');
              `
            }}
            async
            defer
          />

          <Script
            id="gtm-snippet"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PHSL8S4');
              `
            }}
            getScriptOption="afterInteractive"
            async
            defer
          />
        </>
      )}
      <SiteContextProvider>
        <div className="elocal-call-a-pro-root">
          <Component {...pageProps} />
          <AbandonFlowModal />
        </div>
      </SiteContextProvider>
    </main>
  );
}

export function reportWebVitals(metric) {
  metric.label === 'web-vital' && console.debug(metric);
}

export default appWithTranslation(MyApp);
