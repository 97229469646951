import { QuestionInput } from '@/components/InputFields/types';

type Service = {
  need_id: string;
  label: string;
  type?: string;
};

export interface CategoryVariant {
  id: number;
  name: string;
  name_adjective: string;
  name_singular?: string;
  name_plural?: string;
  name_possessive?: string;
  url_slug: string;
  phone_number: number;
  ts_img_url: string;
  ts_headline: string;
  ts_copy: string;
  variants: {
    variant: string;
    theme_class?: string;
    heading?: string;
    subheading?: string;
    workTypeInput?: {
      inputType: string;
      label: string;
      options: {
        label: string;
        value: string;
      }[];
    };
  }[];
  meta: {
    author: string;
    category_name: string;
    og_description: string;
    og_title: string;
    og_url: string;
    description: string;
    keywords: string;
    title: string;
    canonical: string;
  };
  workTypeInput?: QuestionInput;
  questions: QuestionInput[];
  services: Service[];
}

export const CATEGORY_VARIANTS: CategoryVariant[] = [
  {
    id: 11000,
    name: 'Roofers',
    name_adjective: 'Roofing',
    name_singular: 'Roofer',
    name_plural: 'Roofers',
    name_possessive: "Roofer's",
    url_slug: 'roofers',
    phone_number: 8667209105,
    ts_img_url: '/images/roofing-collage.webp',
    ts_headline: "From minor leaks to storm damage to your dream roof, we've got access to local solutions.",
    ts_copy: "You need to trust the service provider keeping a roof over your head. We're your connection to experienced and trusted roofing solutions without dead end searches. ",
    variants: [
      {
        variant: 'v1',
        theme_class: 'light'
      },
      {
        variant: 'v2',
        theme_class: 'dark'
      },
      {
        variant: 'v3',
        heading: '{{City|Local}} Roofing Experts - Install, Replace, Repair',
        workTypeInput: {
          inputType: 'radio',
          label: 'What type of job do you need done?',
          options: [
            { label: 'New Roof', value: 'new' },
            { label: 'Repair', value: 'repair' }
          ]
        }
      },
      {
        variant: 'v4',
        theme_class: 'white',
        heading: 'Local {{City|}} Contractors for your Roofing Needs',
        subheading: 'Easily Contact your Local Roofer'
      }
    ],
    meta: {
      author: 'CallAPro.com',
      category_name: 'Roofers',
      og_description: "Need a Roofer?  Search the Nation's #1 Directory of Local Roofers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Roofer Today!",
      og_title: 'Find Local Roofers | #1 Roofer Directory | Get Roofing Quotes',
      og_url: 'https://www.callapro.com/roofers',
      description: "Need a Roofer?  Search the Nation's #1 Directory of Local Roofers.  It's Fast, Free and Easy to Use.  Get a Quote from a Local Roofer Today!",
      keywords:
        'Roofers, Roofer, find a Roofer, local Roofers, local Roofer, Roofing, Roofing companies, Roofing contractors, residential Roofers, commercial Roofers, licensed contractors, emergency Roofers, Roof Leaks, Roofing Problems and Repairs',
      title: 'Find Local Roofers | #1 Roofer Directory | Get Roofing Quotes',
      canonical: 'https://www.callapro.com/roofers/'
    },
    workTypeInput: {
      inputType: 'radio',
      label: 'What type of job do you need done?',
      options: [
        { label: 'New Roof', value: 'new' },
        { label: 'Repair', value: 'repair' }
      ]
    },
    questions: [
      {
        inputType: 'radio',
        label: 'Are you the homeowner/decision maker?',
        options: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' }
        ],
        acceptedAnswers: ['Yes'],
        validationMessage: 'You must be the homeowner or decision maker to make a request.'
      },
      {
        inputType: 'radio',
        label: 'Is this a residential or commercial project?',
        options: [
          { label: 'Residential', value: 'Residential' },
          { label: 'Commercial', value: 'Commercial' }
        ]
      },
      {
        inputType: 'radio',
        label: 'Is this a single-family home?',
        options: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' }
        ]
      },
      {
        inputType: 'numeric',
        label: 'What is the approximate age of your roof?',
        min: 0,
        max: 100
      }
    ],
    services: [
      // new
      {
        need_id: '11000-10157',
        label: 'Asphalt Shingle',
        type: 'new'
      },
      {
        need_id: '11000-10158',
        label: 'Cedar/Wood',
        type: 'new'
      },
      {
        need_id: '11000-10159',
        label: 'Clay/Tile',
        type: 'new'
      },
      {
        need_id: '11000-10160',
        label: 'Flat/Rubber/Hot Asphalt',
        type: 'new'
      },
      {
        need_id: '11000-10161',
        label: 'Metal',
        type: 'new'
      },
      {
        need_id: '11000-10162',
        label: 'Natural Slate',
        type: 'new'
      },
      // repair
      {
        need_id: '11000-10152',
        label: 'Capping/Flashing',
        type: 'repair'
      },
      {
        need_id: '11000-14363',
        label: 'General Roof Repairs',
        type: 'repair'
      },
      {
        need_id: '11000-10163',
        label: 'Roof Certifications',
        type: 'repair'
      },
      {
        need_id: '11000-10165',
        label: 'Roof Drainage/Repitching',
        type: 'repair'
      },
      {
        need_id: '11000-10166',
        label: 'Asphalt Shingle',
        type: 'repair'
      },
      {
        need_id: '11000-10167',
        label: 'Cedar/Wood',
        type: 'repair'
      },
      {
        need_id: '11000-10168',
        label: 'Clay/Tile',
        type: 'repair'
      },
      {
        need_id: '11000-10169',
        label: 'Flat/Rubber/Hot Asphalt',
        type: 'repair'
      },
      {
        need_id: '11000-10170',
        label: 'Metal',
        type: 'repair'
      },
      {
        need_id: '11000-10175',
        label: 'Ventilation/Ridge Vents',
        type: 'repair'
      }
    ]
  },
  {
    id: 160,
    name: 'Window Installers',
    name_adjective: 'Window',
    url_slug: 'windows',
    phone_number: 8559464443,
    ts_img_url: '/images/windows-collage.webp',
    ts_headline: "From winterizing against the elements to pane repair to your dream windows, we've got access to local solutions.",
    ts_copy: "You need to trust the service provider keeping your view into the world spotless. We're your connection to experienced and trusted window experts without dead end searches.",
    variants: [
      {
        variant: 'v1',
        theme_class: 'light'
      },
      {
        variant: 'v2',
        theme_class: 'dark'
      }
    ],
    meta: {
      author: 'CallAPro.com',
      category_name: 'Window Installers',
      og_description: "Looking for Window Installers? Search Our National Directory of Local Window Installers. It's Fast, Free, and Easy to Use.",
      og_title: 'Local Window Installers | CallAPro.com',
      og_url: 'https://www.callapro.com/windows',
      description: "Looking for Window Installers? Search Our National Directory of Local Window Installers. It's Fast, Free, and Easy to Use.",
      keywords: 'Window Installers, listings, national',
      title: 'Local Window Installers | CallAPro.com',
      canonical: 'https://www.callapro.com/windows/'
    },
    questions: [
      {
        inputType: 'radio',
        label: 'Are you the homeowner/decision maker?',
        options: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' }
        ],
        acceptedAnswers: ['Yes'],
        validationMessage: 'You must be the homeowner or decision maker to make a request.'
      },
      {
        inputType: 'radio',
        label: 'Is this a residential or commercial project?',
        options: [
          { label: 'Residential', value: 'Residential' },
          { label: 'Commercial', value: 'Commercial' }
        ]
      },
      {
        inputType: 'radio',
        label: 'Is this your primary or secondary (rental/vacation) home?',
        options: [
          { label: 'Primary Home', value: 'Primary' },
          { label: 'Secondary (rental/vacation) Home', value: 'Secondary' }
        ]
      },
      {
        inputType: 'numeric',
        label: 'How many windows are you looking to replace?',
        min: 0,
        max: 100
      }
    ],
    services: [
      {
        need_id: '160-11855',
        label: 'Window Installation'
      },
      {
        need_id: '160-11854',
        label: 'Window Replacement'
      },
      {
        need_id: '848-14597',
        label: 'Window Repair'
      }
    ]
  }
];
