import React from 'react';

import { SiteContext } from '@/contexts/site-context';

import { formatPhoneNumber } from '@/helpers/helpers';

import PhoneIcon from 'svgs/phone.svg';

import * as styles from './index.module.scss';

const AbandonFlowModal = () => {
  const [site, setSite] = React.useContext(SiteContext);

  React.useEffect(() => {
    if (site.isAbandonModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [site.isAbandonModalOpen]);

  return (
    <>
      {site.isAbandonModalOpen && (
        <div className={styles['abandon-flow-modal-container']}>
          <div className={styles['modal-container']}>
            <div className={styles['body']}>
              <h2>Didn&apos;t find what you are looking for?</h2>
              <div className={styles['content-container']}>
                <p>
                  Call <a href="tel:8776412485">{formatPhoneNumber('8776412485')}</a> and we&apos;ll get you connected to a Pro near you.
                </p>
                <a href="tel:8776412485" className="button alt">
                  <PhoneIcon />
                  {formatPhoneNumber('8776412485')}
                </a>
              </div>
              <a
                href="#"
                className={styles['close-trigger']}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  sessionStorage.removeItem('hasEnteredProfindrFlow');
                  setSite({
                    ...site,
                    isAbandonModalOpen: false
                  });
                }}
              >
                Close
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AbandonFlowModal;
