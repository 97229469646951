/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useRouter } from 'next/router';
import { throttle } from 'lodash-es';

import { CATEGORY_SUBSETS } from './categories_subset';
import { CATEGORY_VARIANTS } from './category_variants';
import { STATES } from './states';

const PHONE_NUMBER = '8335971265';

export const SiteContext = React.createContext();

export const SiteContextProvider = (props) => {
  const [state, setState] = React.useState({
    city: '',
    region: '',
    phone_number: '',
    categories: CATEGORY_SUBSETS,
    statesData: STATES,
    business_sign_up: {},
    isAbandonModalOpen: false,
    base_phone_number: PHONE_NUMBER,
    isLoadingSiteData: true,
    leadFormData: null
  });
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 480 ? window.innerWidth : false);
    };

    const throttleCheckMobile = throttle(checkMobile, 200);

    if (window) {
      checkMobile();
    }

    window.addEventListener('resize', throttleCheckMobile);

    return () => window.removeEventListener('resize', throttleCheckMobile);
  }, []);

  const router = useRouter();

  React.useEffect(() => {
    // If there's a querystring, store it to session.
    // Don't use router.query... it's not very good.
    const qs = router.asPath.split('?')[1];
    if (qs) {
      sessionStorage.setItem('qs', qs);
    } else {
      sessionStorage.removeItem('qs');
    }
  }, []);

  React.useEffect(() => {
    const fetchGeoInfo = async () => {
      // Check for a qs parameter of either "loc" or "int". If either exists, use the value to check for the Google location.
      const qs = router.asPath.split('?')[1];
      const google_location_keys = [];
      const locations = [];
      const geo = {};

      if (new URLSearchParams(qs).get('loc')) google_location_keys.push(new URLSearchParams(qs).get('loc'));
      if (new URLSearchParams(qs).get('int')) google_location_keys.push(new URLSearchParams(qs).get('int'));

      if (google_location_keys.length) {
        for (let i = 0; i < google_location_keys.length; i++) {
          const response = await fetch(`https://marketing-api-dev.elocal.com/api/rest/google_locations?criteria_id=${google_location_keys[i]}`);
          const data = await response.json();
          if (data.google_locations && data.google_locations.length > 0 && data.google_locations[0].city) {
            locations.push(data.google_locations[0].city);
          }
        }

        if (locations.length === 2) {
          geo.location = `${locations[0]} & ${locations[1]}`;
          geo.city = `${locations[0]} & ${locations[1]}`;
        } else if (locations.length === 1) {
          geo.location = locations[0];
          geo.city = locations[0];
        }

        localStorage.setItem('geoInfo', JSON.stringify(geo));
      }

      if (!geo.location) {
        try {
          const response = await fetch('https://ipwhois.pro/?key=J7OPppp50QNcRxNv');
          if (response.ok) {
            const geoAPIData = await response.json();

            if (geoAPIData && geoAPIData.city && geoAPIData.region_code) {
              const location = `${geoAPIData.city}, ${geoAPIData.region_code}`;
              const geo = {
                location: location || '',
                city: geoAPIData.city || '',
                region_code: geoAPIData.region_code || '',
                zip_code: geoAPIData.postal || '',
                version: '2.0'
              };

              localStorage.setItem('geoInfo', JSON.stringify(geo));
            }
          }
        } catch (error) {
          // Fail gracefully by doing nothing
        }
      }

      // Try to get the phone number.
      // Is there a campaign ID with a related phone number?
      const utm_campaign_id = new URLSearchParams(qs).get('utm_campaign_id');
      let phone_number = null;

      if (utm_campaign_id) {
        // check for a match
        const req = await fetch(`${CALLAPRO_WEBSITE_URL}/api/getCampaignPhoneNumber`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ utm_campaign_id })
        });

        if (req.status === 200) {
          const data = await req.json();
          phone_number = data.phone_number;
        }
      }

      // no phone number found from utm params
      if (!phone_number) {
        // Is the user on a category path?
        const paths = router.asPath.split('/');
        let found_category = null;
        paths.map((path) => {
          const slug = path.toLowerCase();
          if (paths.find((p) => p === 'acq')) {
            found_category = CATEGORY_VARIANTS.find((category) => category.url_slug === paths[1]);
          } else {
            if (slug.length > 0 && !found_category) {
              found_category = CATEGORY_SUBSETS.find((category) => category.url_slug === slug);
            }
          }
        });

        if (found_category) {
          phone_number = found_category.phone_number;
        }
      }

      // Have to set a phone number because we don't know if gtag ever calls the callback.
      setState((prevState) => ({
        ...prevState,
        location: geo.location,
        phone_number: phone_number || prevState.phone_number || PHONE_NUMBER,
        isLoadingSiteData: false
      }));
    };

    fetchGeoInfo();
  }, []);

  return <SiteContext.Provider value={[state, setState, isMobile]}>{props.children}</SiteContext.Provider>;
};
