import { v4 as uuidv4 } from 'uuid';
import DeviceDetector from 'device-detector-js';

import { applicationSettings } from '@/constants';

const { CALLAPRO_WEBSITE_URL } = applicationSettings;

const getSessionId = () => {
  if (process.browser) {
    // Check if the session ID is already stored in local storage
    let session_id = sessionStorage.getItem('session_id');

    // If not, generate a new session ID and store it
    if (!session_id) {
      session_id = uuidv4();
      sessionStorage.setItem('session_id', session_id);
    }

    return session_id;
  }
  return null;
};

const getUserId = () => {
  if (process.browser) {
    // Check if the user ID is already stored in local storage
    let user_id = localStorage.getItem('user_id');

    // If not, generate a new user ID and store it
    if (!user_id) {
      user_id = uuidv4();
      localStorage.setItem('user_id', user_id);
    }

    return user_id;
  }
  return null;
};

const trackEvent = async ({ event_type, event_category, event_action, event_label = undefined }) => {
  try {
    if (process.browser) {
      const deviceDetector = new DeviceDetector();
      const deviceInfo = deviceDetector.parse(navigator.userAgent);

      const payload = {
        user_id: getUserId(),
        session_id: getSessionId(),
        event_type: event_type,
        event_category: event_category,
        event_action: event_action,
        event_label: event_label,
        page_url: window.location.href,
        referrer_url: document.referrer,
        device_type: deviceInfo.device.type || 'unknown',
        device_model: deviceInfo.device.model || 'unknown',
        browser_type: deviceInfo.client.name || 'unknown',
        operating_system: deviceInfo.os.name || 'unknown',
        screen_resolution: `${window.screen.width}x${window.screen.height}`,
        bot: deviceInfo.bot,
        domain: window.location.host
      };

      // // Log the event data or send it to your analytics server
      // console.log(payload)

      // Add your logic to save the event data to a database or analytics service
      const response = await fetch(`${CALLAPRO_WEBSITE_URL}/api/storeTrackingEvent`, {
        method: 'POST',
        body: JSON.stringify(payload)
      });
      await response.json();
    }
  } catch (e) {
    console.debug('tracking not collected', e);
  }
};

export { trackEvent };
