import { isEqual } from 'lodash-es';

const STATE_ABBREVIATIONS = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

const findLinksRegex = /(?:https*:\/\/)?(?:www\.)?[a-zA-Z0-9-]*\.[a-zA-Z]{2,}(?:\/\S*)?/g;
const findTagsRegex = /<[^>]*>/g;
const findInvalidCharactersRegex = /[^A-Za-z0-9'",.?!:;$& ]/g;

/**
 * Filters non-punctuation special characters, tags, and links from the passed string
 * @returns filtered text
 */
const filterUserInput = (text) => {
  if (!text) return;
  return text.replace(findLinksRegex, '').replace(findTagsRegex, '').replace(findInvalidCharactersRegex, '');
};

/**
 * Checks the valididy of user input using regex
 *
 * @returns false if text contains invalid characters, tags, or links or if text is undefined
 */
const validUserInput = (text) => {
  if (!text) return false;
  if (text.match(findLinksRegex)) return false;
  if (text.match(findTagsRegex)) return false;
  if (text.match(findInvalidCharactersRegex)) return false;

  return true;
};

const cleanHeadline = (str = '', location = '') => {
  // console.log(str, location)
  const placeholderRegex = /{{City\|([^}]*)}}/g;

  const cleanedStr = str.replace(placeholderRegex, (match, placeholderValue) => {
    const cityValue = location || (placeholderValue.trim() !== '' ? placeholderValue : '');
    const space = cityValue ? ' ' : '';
    return `${space}${cityValue}`;
  });

  return cleanedStr;
};

const cleanNumber = (str = '') => {
  return str.replace(/\D/g, '');
};

const formatCurrency = (amount) => {
  if (!amount || isNaN(amount)) {
    return '$0';
  }

  return `$${parseFloat(amount).toLocaleString('en-US', {
    styles: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

const formatDate = (date) => {
  if (!date) return '';

  try {
    const d = new Date(`${date}T00:00:00`);
    return d.toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
  } catch {
    () => {
      return date;
    };
  }

  return date;
};

const formatPhoneNumber = (str = '') => {
  const cleaned = ('' + str).replace(/\D/g, '').substring(0, 10);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return;
};

const getCleanLabel = (field) => {
  const LABELS = {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email Address',
    phone: 'Phone Number',
    tcpa_consent: 'Consent',
    zip_code: 'Zip Code'
  };

  return LABELS[field] || '';
};

const getGeoFromLocalStorage = () => {
  let geoInfo = null;
  try {
    geoInfo = JSON.parse(localStorage.getItem('geoInfo'));

    // Clean up any stale data.
    if (!geoInfo.location || geoInfo.version !== '2.0') return null;

    // Clean up the city... just in case.
    if (geoInfo.city) geoInfo.city === prettifyCity(geoInfo.city);

    // Let's make sure it's not gibberish...  As best we can.
    if (STATE_ABBREVIATIONS.indexOf(geoInfo.region_code.toUpperCase()) === -1) {
      // Toss it.
      geoInfo = {};
      localStorage.removeItem('geoInfo');
    }
  } catch (e) {}
  return geoInfo;
};

const getBlogLinkUrl = (article) => {
  return `/news/${article.category_slug}/faq/${article.url_slug}`;
};

const getLocationFromZip = async (zip, callback = () => {}) => {
  fetch(`https://secure.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID="100ELOCA1113"><ZipCode ID= "0"><Zip5>${zip}</Zip5></ZipCode></CityStateLookupRequest>`)
    .then((response) => response.text())
    .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
    .then((xml) => {
      callback({
        city: xml.getElementsByTagName('City')[0]?.childNodes[0]?.nodeValue,
        region: xml.getElementsByTagName('State')[0]?.childNodes[0]?.nodeValue,
        zip_code: xml.getElementsByTagName('Zip5')[0]?.childNodes[0]?.nodeValue
      });
    });
};

const isWebpSupported = () => {
  let support;

  if (typeof support !== 'undefined') {
    return support;
  }

  const elem = typeof document === 'object' ? document.createElement('canvas') : {};
  support = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;

  return support;
};

const prettifyCity = (city = 'Your Area') => {
  return city
    .split(/[\s-]/)
    .map((section) => section.charAt(0).toUpperCase() + section.slice(1).toLowerCase())
    .join(' ');
};

const swapPhone = (content, phone) => {
  const regex = /<a\s+href="tel:\d+">(\(\d+\)\s*\d+\-\d+)<\/a>/g;
  return content.replace(regex, `<a href="tel:${phone}">${formatPhoneNumber(phone)}</a>`);
};

const updateLocalStorage = () => {
  try {
    console.debug('updateLocalStorage triggered');

    let params = {};

    // Check for a qs.
    let qs = {};
    new URLSearchParams(window.location.search).forEach((value, key) => (qs[key] = value));

    // Clean up localStorage.
    const savedParams = localStorage.getItem('qs_params');
    try {
      params = JSON.parse(savedParams);
      const now = new Date().getTime();
      if (params.ttl && params.ttl < now) {
        localStorage.removeItem('qs_params');
        console.debug('removed qs_params from localStorage');
      }
    } catch (e) {}

    // Clean up params (need to remove ttl if it exists in order to deep compare against qs)
    if (params && params.ttl) {
      delete params.ttl;
    }

    // Clean up qs (remove zip)
    if (qs && qs.zip) {
      delete qs.zip;
    }

    if (Object.keys(qs).length && !isEqual(qs, params)) {
      console.debug('updating qs_params in localStorage');
      localStorage.setItem(
        'qs_params',
        JSON.stringify({
          ...qs,
          ttl: new Date().getTime() + 6.048e8 // add 1 week in milliseconds
        })
      );
    } else {
      console.debug('no change to qs_params in localStorage');
    }
  } catch (e) {
    console.debug('localStorage not available');
  }
};

export {
  validUserInput,
  filterUserInput,
  cleanHeadline,
  cleanNumber,
  formatCurrency,
  formatDate,
  formatPhoneNumber,
  getCleanLabel,
  getGeoFromLocalStorage,
  getBlogLinkUrl,
  getLocationFromZip,
  isWebpSupported,
  prettifyCity,
  swapPhone,
  updateLocalStorage
};
